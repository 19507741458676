import React, { useEffect, useContext } from 'react'
import { LangContext } from "../../store/lang-context";
import useHttp from "../../hooks/useHttp";
import OutstandingTitle from "../../components/UI/Content/OutstandingTitle";
import Information from "../../components/UI/Content/Information";
import ProjectShowroom from "../../components/Projects/ProjectShowroom/ProjectShowroom";
import Loader from "../../components/UI/Loader/Loader";
import ErrorFeedback from "../../components/UI/ErrorFeedback/ErrorFeedback";
import styles from './Projects.module.css'

function Projects(){

    // Context
    const { activeLang } = useContext(LangContext)

    // HTTP Hook
    const { isLoading, success, error, data, sendRequest } = useHttp()

    // Effects
    useEffect(() => {
        sendRequest({url: 'projects/'})
    }, [])

    return (
        <div className={styles['projects']}>
            <OutstandingTitle activeLang={activeLang} engText={"Projects I've Worked On"} spaText={'En Que He Trabajado'}/>
            <Information activeLang={activeLang}
                         engText={"Projects showcase practical skills and creativity in real-world applications. They reflect the ability to tackle challenges and deliver effective solutions. Each project represents a unique opportunity to learn and grow, enhancing problem-solving skills and technical expertise. A diverse portfolio demonstrates versatility and a commitment to continuous improvement, making it an essential aspect of professional development.\n"}
                         spaText={"Los proyectos muestran habilidades prácticas y creatividad en aplicaciones del mundo real. Reflejan la capacidad de enfrentar desafíos y ofrecer soluciones efectivas. Cada proyecto representa una oportunidad única para aprender y crecer, mejorando las habilidades de resolución de problemas y la experiencia técnica. Un portafolio diverso demuestra versatilidad y un compromiso con la mejora continua, siendo un aspecto esencial del desarrollo profesional.\n"}/>
            {isLoading && <Loader loadingText={'Collecting Projects'}/>}
            {error && <ErrorFeedback error={error}/>}
            {success && data && <ProjectShowroom projectsData={data}/>}
        </div>
    )

}

export default Projects