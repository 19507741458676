import React, { useState } from 'react'
import CredentialCardHeader from './CredentialCardHeader/CredentialCardHeader'
import CredentialGrouping from "./CredentialGrouping/CredentialGrouping";
import CredentialVerification from "./CredentialVerification/CredentialVerification";
import styles from './CredentialCard.module.css'

function CredentialCard(props){

    // Props
    const { credentialGroup, activeCategory, toggleCategoryHandler } = props
    const { id: categoryId, name, name_spanish, total_credentials, subcategories, credentials } = credentialGroup

    // States
    const [ cardExpanded, setCardExpanded ] = useState(false)

    // Classes
    const classes = `${styles['credential-card']} 
                             ${cardExpanded && styles['card-expanded']} 
                             ${activeCategory !== null && activeCategory !== categoryId && styles['card-hidden']}`

    // Event Handlers
    const cardClickHandler = () => {
        toggleCategoryHandler(categoryId)
        setCardExpanded((prevState) => {
            return !prevState
        })
    }

    return (
        <div className={classes} onClick={cardClickHandler}>
            <CredentialCardHeader category={[name, name_spanish]} counter={total_credentials} expanded={cardExpanded}/>
            {cardExpanded && subcategories && subcategories.map((category, index) => {
               return <CredentialGrouping key={index} category={category}/>
            })}
           {cardExpanded && credentials && credentials.map((credential) => {
                return <CredentialVerification credential={credential}/>
            })}
        </div>
    )

}

export default CredentialCard
