import React, { useContext } from 'react'
import { LangContext } from "../../../../../store/lang-context";
import styles from './ProjectCard.module.css'

function ProjectCard(props){

    // Props
    const { project, displayProjectHandler } = props
    const { banner, name: projectName, description, description_spanish } = project

    // Context
    const { activeLang } = useContext(LangContext)

    // Event Handlers
    const displayProjectClickHandler = () => {
        displayProjectHandler(project)
    }

    return (
        <div className={styles['project-card']}>
            <div className={styles['project-banner']}>
                <img src={`https://www.api.lrdev.sealena.com${banner}`}
                     className={styles['project-banner-img']}
                     alt={'Project Banner'}/>
            </div>
            <div className={styles['project-info']}>
                <h2>{projectName}</h2>
                <p className={styles['project-description']}>{activeLang ? description : description_spanish}</p>
                <button type={'button'} onClick={displayProjectClickHandler}>{activeLang ? 'See Project' : 'Ver Proyecto'}</button>
            </div>
        </div>
    )
}

export default ProjectCard
