import React, { useContext } from 'react'
import { LangContext } from "../../../../store/lang-context";
import CollaborationBadge from "./CollaborationBadge/CollaborationBadge";
import SkillTile from "./SkillTile/SkillTile";
import styles from './ProjectViewer.module.css'

function ProjectViewer(props){

    // Props
    const { project, displayProjectHandler } = props
    const { banner, name: projectName, description, description_spanish, url, collaboration, skills } = project

    // Context
    const { activeLang } = useContext(LangContext)

    // Event Handlers
    const visitProjectClickHandler = (event) => {
        event.preventDefault()
        event.stopPropagation()
        window.open(url, '_blank')
    }
    const closeDisplayedProjectHandler = (event) => {
        event.preventDefault()
        event.stopPropagation()
        displayProjectHandler()
    }

    return (
        <div className={styles['viewer']}>
            <div className={styles['project-content']}>
                <div className={styles['banner']}>
                    <img src={`https://www.api.lrdev.sealena.com${banner}`}
                         className={styles['project-banner']}
                         alt={'Project Banner'}/>
                </div>
                <div className={styles['description']}>
                    <h2>{projectName}</h2>
                    <p>{activeLang ? description : description_spanish}</p>
                    {collaboration && <CollaborationBadge/>}
                    <small>{`${skills.length} ${activeLang ? 'skills were used to accomplish this project' : 'Habilidades fueron utilizadas en este proyecto'}`}</small>
                    <div className={styles['project-skills']}>
                        {skills && skills.map((skill, index) => {
                            return <SkillTile key={index} skill={skill}/>
                        })}
                    </div>
                </div>
            </div>
            <div className={styles['viewer-controller']}>
                <button onClick={visitProjectClickHandler}>{activeLang ? 'Visit Project' : 'Visitar Proyecto'}</button>
                <button onClick={closeDisplayedProjectHandler}>{activeLang ? 'Close' : 'Cerrar'}</button>
            </div>
        </div>
    )
}

export default ProjectViewer